import React from 'react'

const Abilities = () => {
    return (
        <div className="grid-wrap">
            <div className="grid abilities">
                <div className="ability-1">
                    <h3>Backend Development</h3>
                    <p>I have a strong background in designing and implementing scalable, efficient backend systems.
                        My experience includes developing robust server-side applications and ensuring smooth
                        integration with various databases. I focus on creating secure, reliable, and maintainable
                        backend solutions that support the overall functionality of web applications.</p>
                </div>
                <div className="ability-2">
                    <h3>Front-End Development</h3>
                    <p>I have experience in developing dynamic web applications that enhance
                        user experiences. My front-end development work is characterized by attention to detail,
                        accessibility, and visual appeal, ensuring that users have a seamless interaction with
                        the applications.</p>
                </div>
                <div className="ability-3">
                    <h3>Software Testing</h3>
                    <p>Ensuring the quality and reliability of software is a critical part of my development process.
                        I conduct thorough testing, both manually and through automated tools, to identify and resolve
                        issues early.</p>
                </div>
                <div className="ability-4">
                    <h3>Client Interaction</h3>
                    <p>I excel in managing client relationships, understanding their unique needs, and maintaining
                        clear and professional communication. My strong interpersonal skills enable me to facilitate
                        effective interactions, ensuring that client expectations are met and often exceeded. </p>
                </div>
            </div>
        </div>
    )
}
export default Abilities
